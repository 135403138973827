import React, { useEffect } from "react"
import queryString from "query-string"
import Layout from "../components/layout"
import SEO from "../components/seo"
import withLocation from "../components/withLocation"
import Signup from "../components/Signup"

const IndexPage = ({ search }) => {
  useEffect(() => {
    if (window && window.analytics) {
      window.analytics.track({
        event: `Backdrop: Industry Signup Page Visited`,
        userId: search.customer,
      })
    }
  }, [])
  return (
    <Layout>
      <SEO title="Signup" />
      <Signup
        code={search.code}
        memberGroup={search.member_group}
        utmSource={search.utm_source}
      />
    </Layout>
  )
}

export default withLocation(IndexPage)
